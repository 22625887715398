import React, { Fragment, Component } from 'react';
import { Link, StaticQuery, graphql, withPrefix } from 'gatsby';
import { decodeEntities } from '../utils/htmlParse';
// import { isValidDate } from '../utils/helpers';
import { ViewMorePosts } from './ViewMorePosts';
// import { NoSearchResults } from './NoSearchResults';
import FormModalButton from './FormModalButton';
import './SearchResultsList.scss';

class SearchResultsList extends Component {

  render() {
    const {
      visibleItems,
      allMatchingItems,
      totalItems,
      showMorePostsHandler,
      filterItems,
      activeFilter,
      data
    } = this.props;

    // Handle no search results
    if (!visibleItems || visibleItems.length < 1) return <div className="no-results">No Results found</div>;
    const { siteUrl } = data.site.siteMetadata;
    const allResourceFiles = data.allWordPressResourceFiles.edges.map(({node: resource}) => {
      if (!resource.acf.file) return false;
      return {
        id: resource.acf.file.id,
        url: resource.acf.file.source_url
      };
    }).filter(el => el);
    // Get result filters using result type (see gatsby-config.js)
    const uniqueResultFilters = new Set(allMatchingItems.map(result => result.type));
    const resultFilters = Array.from(uniqueResultFilters);
    return (
      <Fragment>
        {resultFilters && (
          <div className="result-filters">
            {/* {origin !== 'hub' && (
              // eslint-disable-next-line
              <span
                className={`filter ${activeFilter === 0 ? 'active' : ''}`}
                onClick={() => this.filterItems(0)}
              >
                All
              </span>
            )} */}
            {/* eslint-disable-next-line */}
            <span
              className={`filter ${activeFilter === 0 ? 'active' : ''}`}
              onClick={() => filterItems(0)}
            >
              All
            </span>
            {resultFilters.map((filter, index) => {
              return (
                // eslint-disable-next-line
                <span
                  className={`filter ${((index + 1) === activeFilter) ? 'active' : ''}`}
                  onClick={() => filterItems(index + 1, filter)}
                >
                  {filter}
                </span>
              );
            })}
          </div>
        )}
        <div className="results">
          {visibleItems.map(result => {
            const { type } = result;
            const parentPage = result.parentPage || undefined;
            const dateOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
            const formattedDate = new Date(result.date).toLocaleDateString('en-US', dateOptions).replace(/\//g, '.');
            // const mainCategory = result.categories ? result.categories[0] : null;
            const resultSlug = `${parentPage ? `/${parentPage.node.slug}` : ''}/${result.slug}`;
            // const hasImage = result.featuredImage;
            return (
              <div className="result" key={result.slug}>
                <div className="meta">
                  <p>
                    {type && (
                      <span className="type-label">{type.toUpperCase()} /</span>
                    )}
                    <span className="date"> {formattedDate}</span>
                  </p>
                </div>
                <div className="container">
                  {result.node.acf.file___NODE ? (
                    <>
                      <div className="image">
                        {result.featuredImage && <img src={result.featuredImage.source_url} alt="" />}
                      </div>
                      <h3 className="title">{decodeEntities(result.title)}</h3>
                      <FormModalButton
                        siteUrl={siteUrl}
                        file={allResourceFiles.find(file => file.id === result.node.acf.file___NODE).url}
                      />
                    </>
                  ) : (
                    <>
                      {result.featuredImage ? (
                        <Link
                          className="image"
                          to={`/${resultSlug}/`}
                          aria-label={decodeEntities(result.title)}
                        >
                          <img src={result.featuredImage.source_url} alt="" />
                        </Link>
                      ) : (
                        <div className="image">
                          <img src={withPrefix('placeholder.jpg')} alt="" />
                        </div>
                      )}
                      <h3 className="title"><Link to={`/${resultSlug}/`} className="animated-underline" aria-label={decodeEntities(result.title)}>{decodeEntities(result.title)}</Link></h3>
                    </>
                  )}
                  <div className="excerpt" dangerouslySetInnerHTML={{__html: result.excerpt}} />
                </div>
              </div>
            );
          })}
        </div>
        <ViewMorePosts
          currentItems={visibleItems.length}
          totalItems={totalItems}
          showMorePostsHandler={showMorePostsHandler}
        />
      </Fragment>
    );
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
        allWordPressResourceFiles: allWordpressWpResource {
          edges {
            node {
              acf {
                file {
                  id
                  source_url
                }
              }
            }
          }
        }
      }
    `}
    render={data => <SearchResultsList data={data} {...props} />}
  />
)
