import React from 'react';
import './GatedDownloadForm.scss';

export const GatedDownloadForm = (props) => {
  const { file, siteUrl } = props;
  return (
    <>
      <form
        className="gated-download-form"
        method="post"
        action="https://api.formbucket.com/f/buk_IIiGBXA3ZQUEv5Q9ByyctQ4N"
      >
        <h3>Please submit your details below.</h3>
        <p className="subtitle">We&#39;ll send the file to the email you provide.</p>
        <input name="thank_you_url" value={`${siteUrl}/thank-you?query=file`} type="hidden" />
        <input name="file" value={file} type="hidden" />
        {/* eslint-disable-next-line */}
        <label htmlFor="name">Name</label>
        <input type="text" name="name" placeholder="Name" className="name dark" required />
        {/* eslint-disable-next-line */}
        <label>Email</label>
        <input type="email" name="email" placeholder="Email" className="email dark" required />
        {/* eslint-disable-next-line */}
        <label>Phone</label>
        <input type="phone" name="phone" placeholder="Phone" className="phone dark" required />
        <input type="text" name="formbucket" placeholder="Form Bucket" className="formbucket-field" />
        <button className="styled-button" type="submit" aria-label="Submit"><span>Submit</span></button>
      </form>
    </>
  );
}
