import React, { Component } from 'react';
import { Link } from 'gatsby';
import Modal from './Modal';
import { decodeEntities } from '../utils/htmlParse';
import { GatedDownloadForm } from './Forms/GatedDownloadForm';
import './FormModalButton.scss';

export default class FormModalButton extends Component {
  state = {
    showModal: false
  };

  // Returns true if form has already been submitted
  // checkFormCompletion = () => {
  //   const formCompleted = localStorage.getItem("form_completed_capital_partners");
  //   return (formCompleted !== null || formCompleted !== 'null');
  // }

  handleDownloadButtonPress = (event) => {
    return this.openModal(event);

    // const formCompleted = this.checkFormCompletion();
    // console.log('formCompleted', formCompleted);
    // if (formCompleted === false) {
    //   // If form has not been submitted, show gated popup form
    //   console.log('NEED TO SUBMIT FORM');
    //   return this.openModal(event);
    // }
    // // If form has been submitted, allow file download
  }

  openModal = (event) => {
    event.preventDefault();
    this.setState({ showModal: true });
    document.body.classList.add('modal-open');
  }

  closeModal = (event) => {
    event.preventDefault();
    this.setState({ showModal: false });
    document.body.classList.remove('modal-open');
  }

  render() {
    const { file, siteUrl, className = 'styled-button', text = 'Download' } = this.props;
    const { showModal } = this.state;
    return (
      <div className="form-modal">
        <a href={file} onClick={this.handleDownloadButtonPress} className={className}>
          <span>{decodeEntities(text)}</span>
        </a>
        <Modal
          active={showModal}
          closeModal={this.closeModal}
          className="gated-download-form-modal"
        >
          <GatedDownloadForm file={file} siteUrl={siteUrl} />
        </Modal>
      </div>
    );
  }
}
